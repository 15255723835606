.deal-banner {
    display: inline-block;
    background-color: #ff5722; /* Orange color */
    color: #fff; /* White text color */
    padding: 8px 12px;
    font-weight: normal;
    font-size: 12px;
    border-radius: 4px;
    position: relative;
    margin: 10px 0;
    text-align: left;
     /* white-space: nowrap; */
      /*
    Prevent text from wrapping to the next line */
    /* overflow: hidden;  */
    /* Hide any overflowing text */
    /* text-overflow: ellipsis; Show ellipsis if text overflows */
  }
  
  .deal-banner::after {
    content: '';
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #ff5722; /* Same orange color as the banner */
  }
  